import React from 'react';
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import { getAnimationOptionsCCh } from '../../_shared/ConnectChildrenWithWavesAnimation';
import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildrenLazy';
import { getDefaultConnectColors, myAnimationFramerMotionCCh, sxWrapItro, myBaseOptionsCCh } from '../../_shared/IntroTextyConnected'
import { myWavesOptionsCCh } from "../../_shared/wavesConfigurationsCreated"

const ReactVsWordpress = ({ colors = {} }) => {

    const theme = useTheme();
    const defaultThemeColors = getDefaultConnectColors(theme.palette);
    const usedColors = { ...defaultThemeColors, ...colors };
    const { svgPathColor, svgFillColor, startBg, startColor, endBg, endColor } = usedColors;

    const myAnimationOptionsCCh0 = getAnimationOptionsCCh({
        svgPathColor, svgFillColor, startBg, startColor, endBg, endColor, styleItemText: { backgroundColor: "#f3d", color: "#480" },
        animationFramerMotion: myAnimationFramerMotionCCh
    });

    return (
        <Box sx={sxWrapItro} className="intro">
            <h3 id="react-vs-wordpress" className="toc"><span>React alebo Wordpress/Joomla pre webstránky ?</span></h3>
            <ConnectChildrenWithWavesAnimationLazy
                baseOptionsCCh={myBaseOptionsCCh}
                animationOptionsCCh={myAnimationOptionsCCh0}
                wavesOptionsCCh={myWavesOptionsCCh}
                name="react-vs-wordpress"
            >
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="technologia" className="waved toc toc0"><span>Technológia</span></h4>
                    <div>Wordpress a Joomla sú systémy na správu obsahu (CMS), ktoré sú postavené na PHP. React je knižnica najmä pre vytváranie používateľských rozhraní v JavaScripte.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="ucel" className="waved toc toc0"><span>Účel</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div>Wordpress a Joomla sú navrhnuté pre vytváranie a správu obsahu na webových stránkach, ako sú blogy alebo e-shopy. React je určený pre vytváranie dynamických a interaktívnych web aplikácií.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="prisposobitelnost" className="waved toc toc0"><span>Prispôsobiteľnosť</span></h4>
                    <div>Wordpress a Joomla ponúkajú množstvo preddefinovaných tém a doplnkov, ktoré môžu byť prispôsobené. Cez React sa vytvárajú vlastné komponenty a aplikácie od základov.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="vykon" className="waved toc toc0"><span>Výkon</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div>Webstránky vytvorené v Reacte môžu byť rýchlejšie a responzívnejšie, pretože React efektívne spravuje aktualizácie a renderovanie komponentov.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="bezpecnost" className="waved toc toc0"><span>Bezpečnosť</span></h4>
                    <div>Wordpress a Joomla sú často cieľom útokov, pretože sú veľmi populárne a mnoho stránok používa zastaralé verzie alebo neaktualizované doplnky. React je bezpečnejší, ale vyžaduje viac technických znalostí na správne zabezpečenie.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="narocnost" className="waved toc toc0"><span>Náročnosť na učenie</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div>Wordpress a Joomla sú jednoduchšie na naučenie, pretože nevyžadujú programovacie znalosti. React vyžaduje znalosť JavaScriptu a konceptov ako sú stavy a komponenty.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="rozpocet" className="waved toc toc0"><span>Podpora a Komunita</span></h4>
                    <div>Wordpress a Joomla majú veľkú užívateľskú komunitu a množstvo dostupných zdrojov aj pre amatérske vytvorenie web stránok. React má tiež silnú komunitu, ale je viac zameraný na vývojárov.</div>
                </WavedFrameAnimationForChildrenLazy>
            </ConnectChildrenWithWavesAnimationLazy>
        </Box>

    );
};
export default ReactVsWordpress;